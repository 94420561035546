table {
    border-collapse: collapse;
    width: 100%;
    text-align: left;
}

th,
td {
    padding: 8px;
    border: 1px solid #ddd;
}

th {
    background-color: #f2f2f2;
    font-weight: bold;
}

@media only screen and (max-width: 600px) {

    table,
    thead,
    tbody,
    th,
    td,
    tr {
        display: block;
    }

    th {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    td {
        border: none;
        border-bottom: 1px solid #ddd;
        position: relative;
        padding-left: 50%;
    }

    td:before {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        content: attr(data-label);
        font-weight: bold;
    }
}

.query-button {
    background: linear-gradient(to right, #ff416c, #ff4b2b);
    border: none;
    border-radius: 25px;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s ease 0s;
}

.query-button:hover {
    background: linear-gradient(to right, #ff4b2b, #ff416c);
    box-shadow: 0px 15px 20px rgba(255, 65, 108, 0.4);
    transform: translateY(-7px);
}

.query-button:disabled {
    background: linear-gradient(to right, #cccccc, #aaaaaa);
    cursor: not-allowed;
    box-shadow: none;
    transform: none;
    opacity: 0.7;
}

.export-button {
    background: #72A0C1;
    border-radius: 10px;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    padding: 0.2rem 0.4rem;
    margin: 0.6rem 0;
    text-align: center;
    text-decoration: none;
}